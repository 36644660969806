
import * as React from "react";
import { Redirect } from "react-router-dom";
import Router from "../defaults/Router.tsx";
import requestPilets from "../defaults/requestPilets.ts";
import plugins from "../defaults/plugins.ts";
import setup from "../defaults/setup.ts";

function replaceAllParams(path, params) {
  Object.keys(params).forEach(key => {
    path = path.split(':' + key).join(params[key]);
  });

  return path;
}

const NotFoundPage = React.lazy(() => import("../../../../../src/pages/docs/src/NotFound.tsx"));
const version = "1.7.3";
const updated = "Wed Dec 11 2024";
const publicUrl = "/";
const pages = {
  "/": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/guidelines", match.params) }), "/tutorials": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/guidelines", match.params) }), "/tutorials/:id": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/guidelines/tutorials/:id", match.params) }), "/reference/tooling/pilet": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/tooling/build-pilet", match.params) }), "/reference/tooling/piral": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/tooling/build-piral", match.params) }), "/reference/extensions/:id?": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/plugins/:id", match.params) }), "/reference/plugins/:id?": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/plugins/:id", match.params) }), "/reference/codes/:id": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/code/:id", match.params) }), "/code/search": React.lazy(() => import("../../../../../src/pages/docs/src/SearchPage.tsx"))
};
const layouts = {
  "default": React.lazy(() => import("../defaults/Layout.tsx"))
};

export { Router, NotFoundPage, requestPilets, pages, plugins, setup, version, updated, publicUrl, layouts };
